import React from "react";
import "./ProductBanner.css";
import { Container, Row } from "react-bootstrap";
import { Product } from "../../../../components";

const ProductBanner = ({ contentData, url, section }) => {
  const { products_gallery } = section.data;

  return (
    <Container className="products-card">
      <Row>
        {products_gallery.map((item, index) => (
          <Product
            key={index} // Add a unique key for each product
            title={"School furniture"} // Adjust title dynamically if needed
            para={contentData.category_offer_1_description}
            mainColor={index % 2 === 0} // Alternate true (odd) and false (even)
            image={
              item.image
                ? url + item.image
                : require("../../../../asset/images/Home/banner1.png")
            }
          />
        ))}
      </Row>
    </Container>
  );
};

export default ProductBanner;
