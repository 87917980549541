import "./Product.css"
import { useState, useEffect } from 'react';
import React from 'react';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/css';
import { Card, Title } from "../../../../components";
import { Container } from "react-bootstrap";
import axios from 'axios';

const Product = ({ contentData, itemsList, categoryList, url, apiMethod }) => {

  console.log(categoryList);
  const categories =  Array.isArray(categoryList) && categoryList.length > 0 ? categoryList.filter(category => category.is_featured === 1) : []; //should replace with 0
  const [activeFilter, setActiveFilter] = useState('All');
  const [productsData, setProductsData] = useState(itemsList || []);


  const handleClick = (event) => {
    const filterValue = event.target.getAttribute('data-filter');
    setActiveFilter(filterValue);
  };

  useEffect(() => {

      fetchDataByPage();
    
  }, []);




  const fetchDataByPage = async () => {
    const apiURL = `${url}${apiMethod}.content_by_page_name?name=products&start=0&limit=10`;
    try {
      const response = await axios.get(apiURL);
      const data = response?.data?.result?.items?.items;
      setProductsData(data);
    } catch (error) {
      console.log('Error fetching products:', error);
    }
  };



  return (
    <div className={`featured-product ${!productsData ? 'd-none' : ''}`}>
      <Container>
        <Title title={contentData.featured_part_1_title ?? "Featured Product"} para={contentData.featured_part_1_description ?? "Lorem ipsum dolor sit amet, consectetur adipisicing elit."} />
        <ul className="list-slider">
          <li data-filter="All" onClick={handleClick} className={activeFilter === 'All' ? 'active-link' : ''}>
            ALL
          </li>
          {categories.map((category, index) => (
            <li data-filter={`${category.name}`} key={index}
              onClick={handleClick}
              className={activeFilter === `${category.name}` ? 'active-link' : ''}
            >   {category.name}
            </li>
          ))}
        </ul>
        <div className="cards">

          <Splide className="product-slider"
            options={{
              gap: "20px",
              perPage: 5,
              pagination: false,
              arrows: false,
              breakpoints: {
                1250: {
                  perPage: 4,
                },
                992: {
                  perPage: 3,
                },
                768: {
                  perPage: 2,
                },
                576: {
                  perPage: 1,
                  gap: "0",
                  focus: 'center',
                }
              }
            }}
          >

            {Array.isArray(productsData) && productsData.map((product, index) => (
              <SplideSlide key={index}>
                <Card
                  title={product.item_group}
                  para={product.web_item_name}
                  image={
                    product.image
                      ? url + product.image
                      : require("../../../../asset/images/Products/product1.png")
                  }
                  pricebefore={product.before_discount_price_list_rate}
                  priceafter={product.discounted_price_list_rate}
                  sale={"-25%"}
                  name={product.name}
                  currency={product.currency}
                />
              </SplideSlide>
            ))}
          </Splide>

        </div>
        <div className="product-link">
          <a href="/products">Show all products</a>
        </div>
      </Container>
    </div>

  );
};

export default Product;


