import React from 'react';
import "./ReviewClient.css";

const ReviewClient = ({ name, para, image, rating }) => {
    // Convert rating (0-1) to 0-5
    const starRating = rating * 5;
    const fullStars = Math.floor(starRating); // Number of full stars
    const hasHalfStar = starRating % 1 !== 0; // Check if there's a fractional value
    const emptyStars = 5 - fullStars - (hasHalfStar ? 1 : 0); // Remaining stars are empty

    return (
        <div className="feedback-slide">
            <div className="d-flex">
                <svg
                    width="18"
                    height="23"
                    viewBox="0 0 15 26"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        id="Vector"
                        d="M15 7.58245C14.9997 5.92641 14.4634 4.31597 13.4729 2.99713C12.4824 1.67829 11.0921 0.723538 9.51457 0.278709C7.937 -0.166119 6.25879 -0.0765717 4.73628 0.533675C3.21376 1.14392 1.93065 2.24132 1.08291 3.65825C0.23518 5.07518 -0.130569 6.73375 0.0415339 8.38062C0.213636 10.0275 0.914128 11.5721 2.03602 12.7786C3.1579 13.9851 4.63951 14.7871 6.25453 15.0621C7.86956 15.3371 9.52921 15.07 10.98 14.3016C10.4786 16.1824 9.77143 17.7576 8.925 19.0143C7.23215 21.5321 5.01429 22.7498 2.67858 22.7498C2.25234 22.7498 1.84356 22.9211 1.54216 23.2258C1.24076 23.5306 1.07144 23.9439 1.07144 24.3749C1.07144 24.8059 1.24076 25.2193 1.54216 25.524C1.84356 25.8288 2.25234 26 2.67858 26C6.25929 26 9.40072 24.0867 11.5843 20.8431C13.7486 17.6233 15 13.0795 15 7.58245Z"
                        fill="#7C7C7C"
                        fillOpacity="0.2"
                    />
                </svg>
                <svg
                    width="18"
                    height="23"
                    viewBox="0 0 15 26"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        id="Vector"
                        d="M15 7.58245C14.9997 5.92641 14.4634 4.31597 13.4729 2.99713C12.4824 1.67829 11.0921 0.723538 9.51457 0.278709C7.937 -0.166119 6.25879 -0.0765717 4.73628 0.533675C3.21376 1.14392 1.93065 2.24132 1.08291 3.65825C0.23518 5.07518 -0.130569 6.73375 0.0415339 8.38062C0.213636 10.0275 0.914128 11.5721 2.03602 12.7786C3.1579 13.9851 4.63951 14.7871 6.25453 15.0621C7.86956 15.3371 9.52921 15.07 10.98 14.3016C10.4786 16.1824 9.77143 17.7576 8.925 19.0143C7.23215 21.5321 5.01429 22.7498 2.67858 22.7498C2.25234 22.7498 1.84356 22.9211 1.54216 23.2258C1.24076 23.5306 1.07144 23.9439 1.07144 24.3749C1.07144 24.8059 1.24076 25.2193 1.54216 25.524C1.84356 25.8288 2.25234 26 2.67858 26C6.25929 26 9.40072 24.0867 11.5843 20.8431C13.7486 17.6233 15 13.0795 15 7.58245Z"
                        fill="#7C7C7C"
                        fillOpacity="0.2"
                    />
                </svg>
            </div>
            <p>{para}</p>
            <div className="feedback-img">
                <img src={image} alt={name} />
                <h1>{name}</h1>
                <div className="d-flex">
                    {/* Render full stars */}
                    {Array.from({ length: fullStars }).map((_, i) => (
                        <i key={`full-${i}`} className="fa-solid fa-star"></i>
                    ))}
                    {/* Render half star if applicable */}
                    {hasHalfStar && <i className="fa-solid fa-star-half"></i>}
                    {/* Render empty stars */}
                    {Array.from({ length: emptyStars }).map((_, i) => (
                        <i key={`empty-${i}`} className="fa-regular fa-star"></i>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default ReviewClient;
